$(function(){
    // bind change event to select
    $('#dynamic_select').on('change', function () {
        var url = $(this).val(); // get selected value
        if (url) { // require a URL
            window.location = url; // redirect
        }
    });


/*----------------------------------------------------*/
/*  Dates slider
/*----------------------------------------------------*/
    $( "#slider-range" ).slider({
        range: true,
        min: 1800,
        max: 2016,
        values: [ 1800, 1950 ],
        slide: function( event, ui ) {
            $( "#amount" ).val(ui.values[ 0 ] + "-" + ui.values[ 1 ] );
        }
    });
    
    $( "#amount" ).val($( "#slider-range" ).slider( "values", 0 ) + "-" + $( "#slider-range" ).slider( "values", 1 ) );


/*----------------------------------------------------*/
/*  Filter categories
/*----------------------------------------------------*/
    $("ul.sort-source").each(function() {
        var source = $(this);
        var destination = $("ul.sort-destination[data-sort-id=" + $(this).attr("data-sort-id") + "]");
        if(destination.get(0)) {
            $(window).load(function() {
                destination.isotope({
                    itemSelector: ".grid-item",
                    layoutMode: 'sloppyMasonry'
                });
                source.find("a").on("click", function(e) {
                    e.preventDefault();
                    var $this = $(this),
                        filter = $this.parent().attr("data-option-value");
                    source.find("li.active").removeClass("active");
                    $this.parent().addClass("active");
                    destination.isotope({
                        filter: filter
                    });
                    if(window.location.hash != "" || filter.replace(".","") != "*") {
                        self.location = "#" + filter.replace(".","");
                    }
                    return false;
                });
                $(window).on("hashchange", function(e) {
                    var hashFilter = "." + location.hash.replace("#",""),
                        hash = (hashFilter == "." || hashFilter == ".*" ? "*" : hashFilter);
                    source.find("li.active").removeClass("active");
                    source.find("li[data-option-value='" + hash + "']").addClass("active");
                    destination.isotope({
                        filter: hash
                    });
                });
                var hashFilter = "." + (location.hash.replace("#","") || "*");
                var initFilterEl = source.find("li[data-option-value='" + hashFilter + "'] a");
                if(initFilterEl.get(0)) {
                    source.find("li[data-option-value='" + hashFilter + "'] a").click();
                } else {
                    source.find("li:first-child a").click();
                }
            });
        }
    });


/*----------------------------------------------------*/
/*  Show collections categores
/*----------------------------------------------------*/
    $('input[type="checkbox"].collections').on('change', function(){
        if (this.checked) {
            $('.block-'+$(this).val()).toggle();
            if($('.block:visible').length == 0) {
                $('.nothing-to-search').show();
            } else {
                $('.nothing-to-search').hide();
            }
        } else {
            $('.block-'+$(this).val()).toggle();
            if($('.block:visible').length == 0) {
                $('.nothing-to-search').show();
            } else {
                $('.nothing-to-search').hide();
            }
        }
    });


/*----------------------------------------------------*/
/*  Home news carousel
/*----------------------------------------------------*/
    $('#fullwidth_slider').everslider({
        mode: 'carousel',
        moveSlides: 3,
        slideEasing: 'easeInOutCubic',
        slideDuration: 700,
        navigation: false,
        keyboard: true,
        nextNav: '<span class="alt-arrow">Next</span>',
        prevNav: '<span class="alt-arrow">Next</span>',
        ticker: true,
        tickerAutoStart: true,
        tickerHover: true,
        tickerTimeout: 3000
    });


/*----------------------------------------------------*/
/*  Object2VR
/*----------------------------------------------------*/
    if($('#object2vr').length == 1) {
        var ressource = $('#object2vr').data('ressource');
        var ressourceid = $('#object2vr').data('ressourceid');
        var galleryfolder = $('#object2vr').data('galleryfolder');

        // create the object player with the container
        obj = new object2vrPlayer("object2vr");
        skin = new object2vrSkin(obj);
        
        // load the configuration
        obj.readConfigUrl("/out?ressource="+ressource+"&ressourceid="+ressourceid+"&galleryfolder="+galleryfolder);
    }


/*----------------------------------------------------*/
/*  Pinchzoom
/*----------------------------------------------------*/
    $('.pinchzoom').pinchzoomer({
        imageOptions:{
            maxZoom:4,
            zoomStep:1
        }
    });


/*----------------------------------------------------*/
/*  PanoTour
/*----------------------------------------------------*/

    if($('#tourDIV').length == 1) {
        var ressource = $('#tourDIV').data('ressource');
        var ressourceid = $('#tourDIV').data('ressourceid');
        var visitefolder = $('#tourDIV').data('visitefolder');

    //  embedpano({
    //      xml: "/content/"+ressource+"/"+ressourceid+"/medias/"+visitefolder+"/visite.xml",
    //      target: "panoDIV",
    //      passQueryParameters: true,
    //      html5: "only"
    //  });

        embedpano({
            xml:"/content/"+ressource+"/"+ressourceid+"/medias/"+visitefolder+"/visite.xml",
            swf:"/content/"+ressource+"/"+ressourceid+"/medias/"+visitefolder+"/visite.swf",
            target:"panoDIV",
            passQueryParameters:true
        });
    }

});